<script setup lang="ts">
// @ts-expect-error There won't be declaration file for it
import type { Anchor } from 'vuetify/lib/components'
import { localeLink } from '#imports'

const userStore = useUserStore()
const { getImageUrl } = useAzBlobUrl()
const { can } = useAppAuthorizations()

const logout = async () => {
  userStore.logout()
  const { theme } = useThemeConfig()
  theme.value = 'dark' // reset theme to default dark theme
  // Redirect to login page
  await navigateTo(localeLink('login'))
}

const avatarBadgeProps = {
  dot: true,
  location: 'bottom right' as Anchor,
  offsetX: 3,
  offsetY: 3,
  color: 'success',
  bordered: true,
}

async function restartOnboarding() {
  await userStore.updateOnboarded(false)
  const route = useRoute()
  if (route.name?.toString().startsWith('private-catalog-search')) {
    // launch onboarding tour
    useOnboardingTour().start()
  } else {
    await navigateTo(localeLink('private-catalog-search'))
  }
}
</script>

<template>
  <VBadge v-if="userStore.isAuthenticated" v-bind="avatarBadgeProps" id="user-menu">
    <VAvatar class="cursor-pointer" color="primary" variant="tonal">
      <VImg
        :src="
          userStore.user?.avatar
            ? getImageUrl(userStore.user?.avatar)
            : assetGetUrl('images/avatars/avatar-1.png')
        "
      />

      <!-- SECTION Menu -->
      <VMenu activator="parent" location="bottom end" offset="14em">
        <VList>
          <!-- 👉 User Avatar & Name -->
          <VListItem>
            <template #prepend>
              <VListItemAction start>
                <VBadge v-bind="avatarBadgeProps">
                  <VAvatar color="primary" size="2.5em" variant="tonal">
                    <VImg
                      :src="
                        userStore.user?.avatar
                          ? getImageUrl(userStore.user?.avatar)
                          : assetGetUrl('images/avatars/avatar-1.png')
                      "
                    />
                  </VAvatar>
                </VBadge>
              </VListItemAction>
            </template>

            <VListItemTitle class="font-weight-semibold">
              {{ userStore.user?.fullname }}
            </VListItemTitle>
            <VListItemSubtitle class="text-disabled">
              {{ userStore.user?.role }}
            </VListItemSubtitle>
          </VListItem>

          <!-- Divider -->
          <VDivider class="my-2" />

          <!-- 👉 My Favorites -->
          <VListItem :to="localeLink({ name: 'private-user-favorites' })">
            <template #prepend>
              <VIcon class="me-2" icon="mdi-heart-outline" size="22" />
            </template>
            <VListItemTitle>{{ $t('user-menu.My Favorites') }}</VListItemTitle>
          </VListItem>

          <!-- 👉 My Purchases -->
          <VListItem :to="localeLink({ name: 'private-user-purchases' })">
            <template #prepend>
              <VIcon class="me-2" icon="mdi-cart-heart" size="22" />
            </template>
            <VListItemTitle>{{ $t('user-menu.My Purchases') }}</VListItemTitle>
          </VListItem>

          <!-- 👉 My Learning -->
          <VListItem :to="localeLink({ name: 'private-user-learning' })">
            <template #prepend>
              <VIcon class="me-2" icon="mdi-learn-outline" size="22" />
            </template>
            <VListItemTitle>{{ $t('user-menu.My Learning') }}</VListItemTitle>
          </VListItem>

          <!-- 👉 My Account -->
          <VListItem
            v-if="can('manage', 'user-profile')"
            :to="
              localeLink({
                name: 'private-user-account-settings-tab',
                params: { tab: 'account' },
              })
            "
          >
            <template #prepend>
              <VIcon class="me-2" icon="mdi-cog-outline" size="22" />
            </template>
            <VListItemTitle>{{ $t('user-menu.My Account') }}</VListItemTitle>
          </VListItem>

          <!-- Divider -->
          <VDivider class="my-2" />

          <!-- 👉 Onboarding Tour -->
          <VListItem @click="restartOnboarding">
            <template #prepend>
              <VIcon class="me-2" icon="mdi-learn-outline" size="22" />
            </template>
            <VListItemTitle>{{ $t('user-menu.Onboarding') }}</VListItemTitle>
          </VListItem>

          <!-- 👉 Help Center -->
          <VListItem :to="localeLink({ name: 'help' })" target="_blank">
            <template #prepend>
              <VIcon class="me-2" icon="mdi-help-circle-outline" size="22" />
            </template>
            <VListItemTitle>{{ $t('user-menu.Help') }}</VListItemTitle>
          </VListItem>

          <!-- Divider -->
          <VDivider class="my-2" />

          <!-- 👉 Download Desktop -->
          <VListItem :to="localeLink({ name: 'site-desktop-download' })" target="_blank">
            <template #prepend>
              <VIcon class="me-2" icon="mdi-application-outline" size="22" />
            </template>
            <VListItemTitle>{{ $t('user-menu.Desktop') }}</VListItemTitle>
          </VListItem>

          <!-- Divider -->
          <VDivider class="my-2" />

          <!-- 👉 Logout -->
          <VListItem link @click="logout">
            <template #prepend>
              <VIcon class="me-2" icon="mdi-logout-variant" size="22" />
            </template>
            <VListItemTitle>{{ $t('user-menu.Logout') }}</VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>
    </VAvatar>
  </VBadge>
</template>
