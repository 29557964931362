<script setup lang="ts">
const cart = useCart()

const infoBoxDialog = reactive({
  visible: false,
  title: 'cart.dialog-limit-items.title',
  msg: 'cart.dialog-limit-items.message',
})

watch(
  () => cart.maxItemsReached,
  (v) => {
    if (v) {
      infoBoxDialog.visible = true
      cart.maxItemsReached = false
    }
  },
)

const cartPageUrl = computed(() => {
  if (useUserStore().isAuthenticated) return localeLink(`private-shop-cart`)
  else return localeLink(`${useAppContext().shopPath}/cart`)
})
</script>

<template>
  <VBtn :to="cartPageUrl" icon variant="text" color="default" size="small">
    <VBadge v-if="!cart.empty" :content="cart.count" color="secondary" floating>
      <VIcon icon="mdi-cart-outline" size="1.4em" />
    </VBadge>
    <VIcon v-else icon="mdi-cart-outline" size="1.4em" />
  </VBtn>
  <DialogInfo
    v-model="infoBoxDialog.visible"
    icon="mdi-information"
    :title="$t(infoBoxDialog.title)"
    :message="$t(infoBoxDialog.msg)"
    :button-text="$t('Close')"
  />
</template>
