<script setup lang="ts">
// @ts-expect-error There won't be declaration file for it
import type { Anchor } from 'vuetify/lib/components'
import type { LocaleObject } from '@nuxtjs/i18n'
import { useThemeConfig } from '#imports'

interface Props {
  location?: Anchor
}

const props = withDefaults(defineProps<Props>(), {
  location: 'bottom end',
})

// const config = useRuntimeConfig()
const { locales, setLocale } = useI18n()
const { isAppRtl } = useThemeConfig()
const userStore = useUserStore()

const changeLocale = async (localeObj: LocaleObject) => {
  await userStore.saveLocale(localeObj.code)
  setLocale(localeObj.code)
  isAppRtl.value = localeObj.dir === 'rtl'
}
</script>

<template>
  <VBtn icon variant="text" color="default" size="small">
    <VIcon icon="mdi-translate" size="1.4em" />
    <!-- Menu -->
    <VMenu activator="parent" :location="props.location" offset="14em">
      <!-- List -->
      <VList min-width="10em">
        <!-- List item -->
        <VListItem
          v-for="item in locales"
          :key="(item as LocaleObject).code"
          :value="(item as LocaleObject).name"
          @click="changeLocale(item as LocaleObject)"
        >
          <!-- Language icons -->
          <VListItemTitle>
            <VIcon
              v-if="(item as LocaleObject).code === 'en'"
              icon="circle-flags:gb"
              size="1.2em"
              class="mr-2"
            />

            <VIcon
              v-if="(item as LocaleObject).code === 'fr'"
              icon="circle-flags:fr"
              size="1.2em"
              class="mr-2"
            />
            <!-- Language label -->
            {{ (item as LocaleObject).name }}
          </VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>
  </VBtn>
</template>
